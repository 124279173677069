import React from "react"
import {graphql} from "gatsby"
import {injectIntl, useIntl} from "react-intl"
import {Link} from "gatsby-plugin-react-intl"
import Parser from "html-react-parser"

import Layout from "../layout"

const PersonalPage = (props) => {
    const intl = useIntl()
    const lang = intl.locale
    const content = props.data.personalPage

    if (!content) {
        return (
            <Layout/>
        )
    }

    return (
        <Layout>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{margin: 0}}>
                    <li className="breadcrumb-item">
                        {lang === "en"
                            ? <Link to={`/about`}>Laboratory</Link>
                            : <Link to={`/about`}>Лаборатория</Link>
                        }
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{content.frontmatter.memberName}</li>
                </ol>
            </nav>

            <h1 style={{marginTop: `15px`, textAlign: `center`}}>{content.frontmatter.memberName}</h1>
            <div>{Parser(content.html)}</div>
        </Layout>
    )
}

export default injectIntl(PersonalPage)


export const PersonalPageQuery = graphql`
    query PersonalPageByPath($id: String){
        personalPage: markdownRemark(
            frontmatter: {content_type: {eq: "personal_page"}, id: {eq: $id}}
        ){
            html
            frontmatter{
                path
                memberName
            }
        }
    }`